import tennisIcon from '../images/tennis_icon.svg';
import volleyballIcon from '../images/volley_icon.svg';
import basketballIcon from '../images/basket_icon.svg';
import rugbyIcon from '../images/rugby_icon.svg';
import footballIcon from '../images/footbal_icon.svg';
import swimmingIcon from '../images/swimming_icon.svg';
import padelIcon from '../images/padel_icon.svg';
import waterpoloIcon from '../images/waterpolo_icon.svg';
import beachsportsIcon from '../images/beach_sports_icon.svg'
import golfIcon from '../images/golf_icon.svg'

export const ROLES = {player: 0, partner: 1, admin: 2};
export const SLOT_STATES = {available: 0, reserved: 1, unavailable:2};
export const SPORTS =
  {
    Tennis: {
      icon: tennisIcon,
      terrain: ['Terra battuta', 'Erba', 'Erba artificiale', 'Cemento', 'Sintetico']
    },
    Pallavolo: {
      icon: volleyballIcon,
      terrain: ['Parquet', 'Sintetico']
    },
    Basket: {
      icon: basketballIcon,
      terrain: ['Parquet', 'Sintetico']
    },
    Rugby: {
      icon: rugbyIcon,
      terrain: ['Erba', 'Erba artificiale', 'Terra battuta']
    },
    'Calcio (Calcio 5, Calcio 7, Calcio 8, Calcio 11)': {
      icon: footballIcon,
      terrain: ['Erba', 'Erba artificiale', 'Sintetico', 'Cemento']
    },
    Nuoto: {
      icon: swimmingIcon,
      terrain: ['Piscina']
    },
    Padel: {
      icon: padelIcon,
      terrain: ['Terra battuta', 'Erba', 'Erba artificiale', 'Cemento', 'Sintetico']
    },
    Pallanuoto: {
      icon: waterpoloIcon,
      terrain: ['Piscina']
    },
    'Beach sports (volley - soccer - tennis)': {
      icon: beachsportsIcon,
      terrain: ['Sabbia']
    },
    Golf: {
      icon: golfIcon,
      terrain: ['Erba']
    }
  };

export const calendarMessages = {
  allDay: 'Tutti i giorni',
  previous: 'Prima',
  next: 'Dopo',
  today: 'Oggi',
  work_week: 'Sett. Lav.',
  yesterday: 'Ieri',
  tomorrow: 'Domani',
  month: 'Mese',
  week: 'Sett.',
  day: 'Giorno',
  agenda: 'Agenda',
  date: 'Data',
  time: 'Ora',
  event: 'Evento',
  noEventsInRange: 'Non ci sono eventi in questo periodo.',
  showMore: total => `+${total}`
};

export const sharingType = {
  none: 0,
  replayer: 1,
  public: 2
};

export const subscriptionKind = {
  rtvDedicated: 0,
  rtvUnlimited: 1,
  rtvEvent: 2
};

export const subscriptionStatuses = {noSubscription: 0, valid: 1, expired: 2, unlimited: 3, canRequestEvent: 4};

export const imageTypes = {partner: 0, field: 1, logo: 2, rtv: 3, profile: 4, background: 5, sponsor: 6, event: 7, service: 8, partnerVideoCover: 9, category: 10};

export const rtvType = {rtv: 0, academy: 1};

export const sponsorType = {global: 0, local: 1};

export const xmlType = {geometry: 0, hotspot: 1, advertising: 2}

export const renewalType = {lifeTime: 0, fixed: 1, perPlayer: 2}

export const affiliationType = {partner: 0, rtv: 1, coach: 2}

export const durationType = {days: 0, months: 1, years: 2}
